import React, { useState, useContext, useEffect, useRef } from 'react';
import { Route, Switch } from 'react-router-dom';
import useLang from 'src/hooks/useLang';
import Link from 'src/front/components/Link';
import axios from 'axios';
import $ from 'jquery';
import { API_URI, IS_SERVER, WORLD_PARTS } from 'src/constants';
import Helper from 'src/front/helpers/Helper';
import LoadingScreen from 'src/components/LoadingScreen';
import { MAIN_MENU_LINKS } from 'src/front/constants';
import useHandlePopup, { FrontContext } from '../helpers/FrontContext';
import Popup from '../components/Popup';
import { useUserCan } from 'src/hooks/userHooks';
import { useSnackbar } from 'notistack';
import SearchLocation from 'src/front/components/SearchLocation';
import { useHistory } from 'react-router';
import ResizeSensible from 'src/front/components/ResizeSensible';
import MultiRowTabs from 'src/front/components/MultiRowTabs';
import Tabs from 'src/front/components/Tabs';
import DimensionUnitsConvertor from 'src/utils/DimensionUnitsConvertor';
import ComponentStyle from 'src/components/ComponentStyle';
import ReactDOM from 'react-dom';
import UserOptions from 'src/front/components/UserOptions';
import routes from 'src/front/components/RoutesList';

const Portal = ({ children }) => IS_SERVER ? null : ReactDOM.createPortal(children, document.body);

const serverCalculations = () => `{exec}
  dynamic_value = {};

  switch (route?.name) {
    case 'country':
      if (page?.worldPart && page?.page?.link) {
        dynamic_value.weatherMapLink = '/' + page.worldPart + '/' + page.page.link;
      }
      break;

    case 'region city':
      if (page?.worldPart && page?.countryLink && page?.page?.link) {
        dynamic_value.weatherMapLink = '/' + page.worldPart + '/' + page.countryLink + '/' + page.page.link;
      }
      break;

    case 'news list':
      if (page?.country?.world_part && page?.country?.link) {
        dynamic_value.weatherMapLink = '/' + page.country.world_part + '/' + page.country.link;
      }
      break;

    case 'news inner':
      if (page?.country?.world_part && page?.country?.link) {
        dynamic_value.weatherMapLink = '/' + page.country.world_part + '/' + page.country.link;
      }
      break;
  }

  if (!dynamic_value.weatherMapLink) {
    dynamic_value.weatherMapLink = myCities?.length && myCities[0]?.worldPart ?
      '/' + myCities[0].worldPart + myCities[0].link :
      '/';
  }
{/exec}`;

function Top (props) {
  const [pageData, setPageData] = useState(
    props.staticContext && props.staticContext.page ?
    props.staticContext.page.page :
    (typeof window == 'object' && window.__REACT_INIT_DATA__ && window.__REACT_INIT_DATA__.page ? window.__REACT_INIT_DATA__.page.page : null)
  );
  const [countriesData, setCountriesData] = useState(null);
  const [menuActiveItem, setMenuActiveItem] = useState(typeof window == 'object' && window.__REACT_INIT_DATA__ && window.__REACT_INIT_DATA__.menuActiveItem ? window.__REACT_INIT_DATA__.menuActiveItem : null);
  if (typeof window == 'object' && window.__REACT_INIT_DATA__ && window.__REACT_INIT_DATA__.menuActiveItem) delete window.__REACT_INIT_DATA__.menuActiveItem;
  const [countriesListVisible, setCountriesListVisible] = useState(false);
  const lang = useLang('Top');
  const [weatherMapLink, setWeatherMapLink] = useState({});
  const { myCities, popup, dimensionUnits, staticData } = useContext(FrontContext);
  const handleOpenUserMenu = useHandlePopup();
  const userCan = useUserCan();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [visibleTopMenuItems, setVisibleTopMenuItems] = useState(null);
  const [visibleHiddenTopMenuItems, setVisibleHiddenTopMenuItems] = useState(false);
  const closeHiddenTopMenuItems = useRef(() => {
    setVisibleHiddenTopMenuItems(false);
    document.removeEventListener('click', closeHiddenTopMenuItems.current);
  });
  const [headerMenuMoreItemsAtts, setHeaderMenuMoreItemsAtts] = useState({ className: 'animated-visibility' });
  const handleShowMoreMenuItems = useRef(() => {});
  const closeUserMenuRef = useRef(null);
  const isMounted = useRef(true);
  const windowResizeRef= useRef(null);
  const headerRef = useRef(null);
  const [hiddenMenuItems, setHiddenMenuItems] = useState(null);
  const _MAIN_MENU_LINKS = useRef(null);

  if (_MAIN_MENU_LINKS.current === null) {
    _MAIN_MENU_LINKS.current = Helper.cloneObject(MAIN_MENU_LINKS);

    if (!IS_SERVER && lang.currentLocalization.country) _MAIN_MENU_LINKS.current.splice(1, 0, { title: lang.currentLocalization.country.name, link: `/${lang.currentLocalization.country.link}/` });
  }

  const handleRsizeTopMenu = () => {
    try {
      const topMenu = document.getElementById('top-menu');
      const topMenuWidth = topMenu.getBoundingClientRect().width;
      const topMenuWidthWithHidden = topMenuWidth - 44;
      const menuItems = topMenu.getElementsByTagName('ul')[0].getElementsByTagName('li');
      let totalWidth = 0;
      let visibleItems = 0;
      let allVisible = true;

      for (let i = 0; i < menuItems.length; i++) {
        const styles = window.getComputedStyle(menuItems[i]);
        const itemWidth = menuItems[i].getBoundingClientRect().width + parseFloat(styles.getPropertyValue('margin-left')) + parseFloat(styles.getPropertyValue('margin-right'));

        if ((totalWidth += itemWidth) <= topMenuWidthWithHidden) visibleItems = i + 1;

        if (totalWidth - topMenuWidth > 1) {
          allVisible = false;
          break;
        }
      }

      if (allVisible) throw true;
      else setVisibleTopMenuItems(visibleItems);
    } catch (ex) {
      setVisibleTopMenuItems(null);
      closeHiddenTopMenuItems.current();
    }
  };

  const handleOpenMyCities = (event) => {
    handleOpenUserMenu({
      event,
      element: $('#my-cities-popup > div'),
      name: 'my-cities-popup',
      closeCheck: (ev) => ev.target.id === 'my-cities-popup',
    });
  };

  const handleShowHiddenTopMenuItems = (ev) => {
    ev.preventDefault();

    if (!visibleHiddenTopMenuItems) {
      document.addEventListener('click', closeHiddenTopMenuItems.current);

      setVisibleHiddenTopMenuItems(true);
    }
  };

  const handleSetWorldPart = (worldPart) => staticData.setTopCountriesData({ ...countriesData, subRegion: 0, worldPart });

  const handleWeatherMapClick = () => staticData.closeMapLink = window.location.href.replace(/https?:\/\/[^/]+/, '');

  if (!IS_SERVER) {
    if (!weatherMapLink.current) {
      switch (window.__REACT_INIT_DATA__?.route?.name) {
        case 'country': 
          if (window.__REACT_INIT_DATA__?.page?.worldPart && window.__REACT_INIT_DATA__?.page?.page?.link) {
            weatherMapLink.current = `/${window.__REACT_INIT_DATA__.page.worldPart}/${window.__REACT_INIT_DATA__.page.page.link}`;
          }
          break;

        case 'region city': 
          if (window.__REACT_INIT_DATA__?.page?.worldPart && window.__REACT_INIT_DATA__?.page?.countryLink && window.__REACT_INIT_DATA__?.page?.page?.link) {
            weatherMapLink.current = `/${window.__REACT_INIT_DATA__.page.worldPart}/${window.__REACT_INIT_DATA__.page.countryLink}/${window.__REACT_INIT_DATA__.page.page.link}`;
          }
          break;

        case 'news list':
          if (window.__REACT_INIT_DATA__?.page?.country?.world_part && window.__REACT_INIT_DATA__?.page?.country?.link) {
            weatherMapLink.current = `/${window.__REACT_INIT_DATA__.page.country.world_part}/${window.__REACT_INIT_DATA__.page.country.link}`;
          }
          break;

        case 'news inner':
          if (window.__REACT_INIT_DATA__?.page?.country?.world_part && window.__REACT_INIT_DATA__?.page?.country?.link) {
            weatherMapLink.current = `/${window.__REACT_INIT_DATA__.page.country.world_part}/${window.__REACT_INIT_DATA__.page.country.link}`;
          }
          break;
      }

      if (!weatherMapLink.current) {
        weatherMapLink.current = myCities[0]?.worldPart && myCities[0].link ?
          `/${myCities[0].worldPart}${myCities[0].link}` :
          '/';
      }
    } else if (weatherMapLink.current === '/' && myCities[0]?.worldPart && myCities[0].link) {
      weatherMapLink.current = `/${myCities[0].worldPart}${myCities[0].link}`;
    }
  }

  staticData.setMenuActiveItem = (v) => setTimeout(() => isMounted.current && setMenuActiveItem(v));
  staticData.setPageDataTop = (...p) => isMounted.current && setPageData(...p);
  staticData.showCountriesList = (...p) => isMounted.current && setCountriesListVisible(...p);
  staticData.setTopCountriesData = (p, v) => isMounted.current && setCountriesData(p ? { ...countriesData, ...typeof p == 'string' ? { [p]: v } : p } : null);
  staticData.setWeatherMapLink = (l) => isMounted.current && setWeatherMapLink({ current: l || (myCities[0]?.worldPart && myCities[0].link && `/${myCities[0].worldPart}${myCities[0].link}`) || '/' });

  useEffect(() => () => {
    ['showCountriesList', 'setPageDataTop', 'setTopCountriesData'].forEach((p) => delete staticData[p]);
    isMounted.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (countriesListVisible && (!countriesData?.countries || !(countriesData.worldPart in countriesData.countries))) {
      axios
          .get(`${API_URI}/top-countries${countriesData?.worldPart ? '/' + countriesData.worldPart : ''}`, { withCredentials: true })
          .then(({ data }) => staticData.setTopCountriesData({
            worldParts: data.worldParts || countriesData?.worldParts,
            countries: { ...countriesData?.countries || {}, [data.worldPart]: data.countries },
            pages: { ...countriesData?.pages || {}, [data.worldPart]: data.page },
            worldPart: data.worldPart,
            subRegion: 0,
          }));
    }
  }, [countriesListVisible, countriesData?.worldPart]);

  if (!IS_SERVER && !lang.loaded) return <LoadingScreen/>;

  const topMenu = (
    <>
      {visibleTopMenuItems !== null && <div id="top-menu-hidden-items">
        <a onClick={handleShowHiddenTopMenuItems} href="#" aria-label="More"></a>
        <ul {...visibleHiddenTopMenuItems ? { className: 'opened' } : {}}>
          {_MAIN_MENU_LINKS.current.filter((l, i) => i >= visibleTopMenuItems).map((l, i) => (
            <li key={i} {...l.props}>
              {
                lang.isLink(l.link) ?
                <span>{l.title.constructor == Array ? Helper.getFieldValue(l.title, lang.currentLanguage._id) : lang.t(l.title)}</span> :
                <Link {...(menuActiveItem == l.link || (new RegExp(`^${lang.l(l.link)}${l.link == '/' ? '$' : '(?:/|$)'}`)).test(props.location.pathname)) && { className: 'active' }} to={lang.l(l.link)}>{l.title.constructor == Array ? Helper.getFieldValue(l.title, lang.currentLanguage._id) : lang.t(l.title)}</Link>
              }
            </li>
          ))}
        </ul>
      </div>}
      <nav id="top-menu" {...visibleTopMenuItems !== null ? { className: 'hidden-menu-items' } : {}}>
        <ResizeSensible callback={handleRsizeTopMenu}>
          {IS_SERVER && '{exec}dynamicValue = staticData.language.localizations[localization].country;{/exec}'}
          <ul>
            {_MAIN_MENU_LINKS.current.map((l, i) => {
              const liProps = Helper.cloneObject(l.props) || {};

              if (visibleTopMenuItems !== null && visibleTopMenuItems <= i) liProps.className = `${liProps.className ? liProps.className + ' ' : ''}absolute v-hidden`;

              return (
                <React.Fragment key={i}>
                  <li {...liProps}>
                    <ResizeSensible callback={handleRsizeTopMenu}>
                      {
                        IS_SERVER ?
                        <>
                          {`{if (${lang.isLink(l.link)}) :}`}
                          <span>{lang.t(l.title)}</span>
                          {`{elseif ((page && page.page && (!dynamicValue || page.page[route?.path == '/:country' ? '_id' : 'country_id'] != dynamicValue._id) && page.worldPart && '/' + page.worldPart == '${l.link}') || (new RegExp('^' + ${lang.l(l.link).replace(/^\{print\s*|\}$/g, '')} + ('${l.link}' == '/' ? '$' : '(?:/|$)'))).test(requestUrl)) :}`}
                          {`{exec}tokens.menuActiveItem = '${l.link}';{/exec}`}
                          <Link className="active" to={lang.l(l.link)}>{lang.t(l.title)}</Link>
                          {'{else:}'}
                          <Link to={lang.l(l.link)}>{lang.t(l.title)}</Link>
                          {'{endif}'}
                        </> :
                        (
                          lang.isLink(l.link) ?
                          <span>{l.title.constructor == Array ? Helper.getFieldValue(l.title, lang.currentLanguage._id) : lang.t(l.title)}</span> :
                          <Link {...(menuActiveItem == l.link || (new RegExp(`^${lang.l(l.link)}${l.link == '/' ? '$' : '(?:/|$)'}`)).test(props.location.pathname)) && { className: 'active' }} to={lang.l(l.link)}>{l.title.constructor == Array ? Helper.getFieldValue(l.title, lang.currentLanguage._id) : lang.t(l.title)}</Link>
                        )
                      }
                    </ResizeSensible>
                  </li>
                  {
                    IS_SERVER && !i &&
                    <>
                      {'{if (dynamicValue) :}'}
                        <li>
                          <ResizeSensible callback={handleRsizeTopMenu}>
                            {`{if (${lang.isLink("{print '/' + dynamicValue.link + '/'}")}) :}`}
                            <span>{'{print Helper.getFieldValue(dynamicValue.name, lang)}'}</span>
                            {`{elseif ((page && page.page && page.page[route == '/:country' ? '_id' : 'country_id'] == dynamicValue._id) || (page && page.page && dynamicValue._id == '5ece60490c5b087ef5130dd1' && ['5ee36202c2b241648cc65489', '5ee36202c2b241648cc65488', '5ee36202c2b241648cc6548b', '5ee36202c2b241648cc6548a'].includes(page.page._id)) || dynamicValue.link == requestUrl) :}`}
                            <Link className="active" to={lang.l("{print '/' + dynamicValue.link + '/'}")}>{'{print Helper.getFieldValue(dynamicValue.name, lang)}'}</Link>
                            {'{else :}'}
                            <Link to={lang.l("{print '/' + dynamicValue.link + '/'}")}>{'{print Helper.getFieldValue(dynamicValue.name, lang)}'}</Link>
                            {'{endif}'}
                          </ResizeSensible>
                        </li>
                      {'{endif}'}
                    </>
                  }
                </React.Fragment>
              );
            })}
          </ul>
        </ResizeSensible>
      </nav>
    </>
  );

  const myCity = (c) => <li key={IS_SERVER ? 'city' : (c?.constructor === Object ? c.id : c)} className="inline-block va-middle relative">
    {
      IS_SERVER ?
      `{literal}
        {if (myCities[i]?.constructor === Object) :}
          {if (${lang.isLink('{print myCities[i].link}')}) :}
          <span class="last-city-name inline-block va-middle text-3 color-4" title="{print myCities[i].name}">{print myCities[i].name}</span>
          {else:}
          <a href="${lang.l('{print myCities[i].link}')}" class="last-city-name inline-block va-middle text-3 color-2 hover-color-1" title="{print myCities[i].name}">{print myCities[i].name}</a>
          {endif}
        {else :}
          <span class="last-city-name inline-block va-middle content-preloader" style="width:50px;height:19px;"></span>
        {endif}
      {/literal}`.replace(/\s{2,}/g, '') :
      (
        c?.constructor === Object ? 
        (
          lang.isLink(c.link) ?
          <span title={c.name} className="last-city-name inline-block va-middle text-3 color-4">{c.name}</span> :
          <Link to={lang.l(c.link)} title={c.name} className="last-city-name inline-block va-middle text-3 color-2 hover-color-1">{c.name}</Link>
        ) :
        <span className="last-city-name inline-block va-middle content-preloader" style={{ width: '50px', height: '19px' }}></span>
      )
    }
    {
      IS_SERVER ?
      `{literal}
        {if (myCities[i]?.constructor === Object) :}
          <img width="284" height="284" alt="Weather" src="{print Helper.getFileUrl('weather-icon', myCities[i].weather.icon + '.svg')}" class="last-city-weater inline-block va-middle">
        {else :}
          <span class="inline-block va-middle content-preloader" style="width:42px;height:42px;"></span>
        {endif}
      {/literal}`.replace(/\s{2,}/g, '') :
      (c?.constructor === Object ? <img width="284" height="284" alt="Weather" src={Helper.getFileUrl('weather-icon', `${c.weather.icon}.svg`)} className="last-city-weater inline-block va-middle"/> : <span className="content-preloader inline-block va-middle" style={{ width: '42px', height: '42px' }}></span>)
    }
    {
      IS_SERVER ?
      `{literal}
        <div class="last-city-tempr fs-15 inline-block va-middle tempr text-4 color-1">
          {if (myCities[i]?.constructor == Object) :}
            {print DimensionUnitsConvertor.temperature(myCities[i].weather.temp, dimensionUnits.temperature)}
          {else :}
            <span class="inline-block va-middle content-preloader" style="width:32px;height:18px;"></span>
          {endif}
        </div>
      {/literal}`.replace(/\s{2,}/g, '') :
      <div className="last-city-tempr fs-15 inline-block va-middle tempr text-4 color-1">{c?.constructor === Object ? DimensionUnitsConvertor.temperature(c.weather.temp, dimensionUnits.temperature) : <span className="inline-block va-middle content-preloader" style={{ width: '32px', height: '18px' }}></span>}</div>
    }
  </li>;

  const accountManagementLinks = [
    { href: '/account-management', title: 'Profile' }, { href: '/account-management/settings', title: 'Settings' },
    { href: '/account-management/notifications', title: 'Notifications' }, { href: '/account-management/widgets', title: 'Widgets' },
    { href: '/account-management/advertising', title: 'Advertising' }
  ].filter((l) => userCan('adminPanelAccess') || ['Profile', 'Widgets', 'Notifications'].includes(l.title));

  const mapRoute = routes.filter((r) => r.name === 'map')[0];

  return (
    <>
      {IS_SERVER && serverCalculations()}
      <ComponentStyle styles={Helper.transformStyles(lang.currentLanguage, [{ src: '/css/default-top.css' }, { src: '/css/default-top-media.css' }])} Preloader={<LoadingScreen/>}>
        <header className="clear" ref={headerRef}>
          <Switch>
            <Route
              key={'map'}
              path={IS_SERVER ? mapRoute.path : (typeof mapRoute.path === 'string' ? lang.l(mapRoute.path) : mapRoute.path.map((p) => lang.l(p)))}
              exact={mapRoute.exact}
              render={(props) => {
                return (
                  <>
                    <UserOptions/>
                    <ShowWorldPartCountries
                      handleSetWorldPart={handleSetWorldPart}
                      countriesData={countriesData}
                      countriesListVisible={countriesListVisible}
                      lang={lang}
                      header={headerRef.current}
                    />
                    {topMenu}
                  </>
                );
              }}
            />
            <Route
              path="*"
              render={(props) => {
                return (
                  <>
                    <div className="clear" id="top-menu-wrapper">
                      <div id="header-logo-wrapper">
                        <div className="logo-background"></div>
                        {
                          (IS_SERVER || lang.isLink('/')) &&
                          <>
                            {IS_SERVER && `{if (${lang.isLink('/')}) :}`}
                            <div id="header-logo">
                              <img className="block" width="187" height="60" title={IS_SERVER ? `{print (page && page.page && ${lang.localizationText('{print page.page.server_logo_title || page.page.logo_title}').replace(/^\{print |\}$/g, '')}) || ''}` : (pageData ? lang.localizationText(pageData.logo_title) : '')} src={IS_SERVER ? "{print Helper.getFileUrl('logotype', staticData.language.localizations[localization].logo && staticData.language.localizations[localization].h1Position ? staticData.language.localizations[localization].logo : 'logo.svg')}" : Helper.getFileUrl('logotype', lang.currentLocalization.logo && lang.currentLocalization.h1Position ? lang.currentLocalization.logo : 'logo.svg')}/>
                            </div>
                            {IS_SERVER && '{endif}'}
                          </>
                        }
                        {
                          (IS_SERVER || !lang.isLink('/')) &&
                          <>
                            {IS_SERVER && `{if (!(${lang.isLink('/')})) :}`}
                            <Link id="header-logo" className="block" to={lang.l('/')}>
                              <img className="block" width="187" height="60" title={IS_SERVER ? `{print (page && page.page && ${lang.localizationText('{print page.page.server_logo_title || page.page.logo_title}').replace(/^\{print |\}$/g, '')}) || ''}` : (pageData ? lang.localizationText(pageData.logo_title) : '')} src={IS_SERVER ? "{print Helper.getFileUrl('logotype', staticData.language.localizations[localization].logo && staticData.language.localizations[localization].h1Position ? staticData.language.localizations[localization].logo : 'logo.svg')}" : Helper.getFileUrl('logotype', lang.currentLocalization.logo && lang.currentLocalization.h1Position ? lang.currentLocalization.logo : 'logo.svg')}/>
                            </Link>
                            {IS_SERVER && '{endif}'}
                          </>
                        }
                      </div>
                      <UserOptions/>
                      <ShowWorldPartCountries
                        showPopupButtonText={lang.t(`List of countries`)}
                        handleSetWorldPart={handleSetWorldPart}
                        countriesData={countriesData}
                        countriesListVisible={countriesListVisible}
                        lang={lang}
                        header={headerRef.current}
                      />
                      <Link onClick={handleWeatherMapClick} className="weather-map text-13 color-7" to={lang.l(IS_SERVER ? "{print dynamic_value.weatherMapLink}" : weatherMapLink.current)}>{lang.t('Weather map')}</Link>
                      {topMenu}
                    </div>
                    {
                      (IS_SERVER || !lang.isLink('/') || lang.currentLocalization.mapType !== 'landing') &&
                      <>
                        {IS_SERVER && "{if (route?.name !== 'home' || staticData.language.localizations[localization].mapType !== 'landing') :}"}
                          <div id="top-search-and-my-cities" className="flex align-center">
                            <div className="h1-share">
                              <h1
                                title={IS_SERVER ? `{print (page && page.page && ${lang.localizationText('{print page.page.server_title_h1 || page.page.title_h1}').replace(/^\{print |\}$/g, '')}) || ''}` : (pageData ? lang.localizationText(pageData.title_h1) : '')}
                                className="text-3 color-1"
                              >
                                {IS_SERVER ? `{print (page && page.page && ${lang.localizationText('{print page.page.server_title_h1 || page.page.title_h1}').replace(/^\{print |\}$/g, '')}) || ''}` : (pageData ? lang.localizationText(pageData.title_h1) : '')}
                              </h1>
                              <a href="#" className="share-page block"></a>
                            </div>
                            <div className="search-form">
                              <SearchLocation
                                types={'countries,regions,cities'}
                                clearButton={false}
                                usePortal={true}
                                selectLocation={(l) => history.push(lang.l(l.link))}
                              />
                            </div>
                            <div className="my-cities-wrapper fs-0">
                              {
                                IS_SERVER ?
                                <>
                                  {'{literal}<ul class="my-cities fs-0 inline-block va-middle">{/literal}'}
                                    {`{for (let i = 0; i < myCities.length; i++) :}`}
                                      {myCity()}
                                    {'{endfor}'}
                                  {'{literal}</ul>{/literal}'}
                                </> :
                                <ul className="my-cities fs-0 inline-block va-middle">{myCities.map(myCity)}</ul>
                              }
                            </div>
                            <a href="#" className="open-my-cities flex dir-col" onClick={handleOpenMyCities}>
                              <span></span>
                              <span></span>
                              <span></span>
                            </a>
                          </div>
                        {IS_SERVER && '{endif}'}
                      </>
                    }
                  </>
                );
              }}
            />
          </Switch>
        </header>
      </ComponentStyle>
    </>
  );
}

function ShowWorldPartCountries({ showPopupButtonText = '', handleSetWorldPart, countriesData, countriesListVisible, lang, header }) {
  const { staticData } = useContext(FrontContext);
  const userOptions = header?.querySelector(':scope .user-options');
  const userOptionsBCR = userOptions?.getBoundingClientRect();
  const countriesButtonBCR = header?.querySelector(':scope .show-world-part-countries')?.getBoundingClientRect();
  const [scrollStyle, setScrollStyle] = useState({});
  const onResizeRef = useRef(null);
  const popupRef = useRef(null);
  const history = useHistory();
  const tabs = countriesData && countriesData.worldParts && countriesData.worldParts.map((p) => ({ key: p.id, name: lang.t(p.name) }));
  const subregions = (countriesData?.worldParts && countriesData.worldParts.filter((wp) => wp.id == countriesData.worldPart)[0].subregions) || [];


  if (onResizeRef.current === null) {
    onResizeRef.current = () => {
      if (popupRef.current?.current) {
        const tabsBCR = popupRef.current.current.querySelector(':scope .page-tabs')?.getBoundingClientRect();
        const visibleBCR = popupRef.current.current.querySelector(':scope .visible')?.getBoundingClientRect();
        let height = window.innerHeight - popupRef.current.current.getBoundingClientRect().top - 60 - 44;
      
        if (tabsBCR) height -= tabsBCR.height;
        if (visibleBCR) height -= visibleBCR.height;
      
        setScrollStyle({ maxHeight: `${Math.max(height, 0)}px` });
      }
    };
  }

  const handleShowPopup = (ev) => {
    staticData.showCountriesList(true);
  };

  const handleClosePopup = (ev) => {
    staticData.showCountriesList(false);
  }

  useEffect(() => {
    if (countriesListVisible) {
      window.addEventListener('resize', onResizeRef.current);
    } else {
      window.removeEventListener('resize', onResizeRef.current);
    }
  }, [countriesListVisible]);

  useEffect(() => {
    return () => {
      window.removeEventListener('resize', onResizeRef.current);
    };
  }, []);

  return (
    <>
      <button onClick={handleShowPopup} className="show-world-part-countries text-18 color-7" href="#">{showPopupButtonText}</button>
      <Popup
        className="world-part-countries-popup"
        anchor={userOptions}
        isOpened={countriesListVisible}
        onClose={handleClosePopup}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        popupOrigin={{ horizontal: 'right', vertical: 'top' }}
        popupRef={popupRef}
        offset={{ left: 0, top: 10 }}
        style={{ '--countries-button-center': `${userOptionsBCR && countriesButtonBCR ? userOptionsBCR.right - countriesButtonBCR.right + countriesButtonBCR.width / 2 : 0}px` }}
      >
        <ResizeSensible callback={onResizeRef.current}>
          {tabs && <>
            <Tabs
              tabs={tabs}
              tab={countriesData?.worldPart}
              setTab={handleSetWorldPart}
            />
          </>}
          <div className="world-part-countries-popup-content">
            {
              countriesData && countriesData.worldParts && countriesData.countries && countriesData.worldPart in countriesData.countries ?
              <>
                <div className={`visible${subregions.length > 1 ? ' with-subregions' : ''}`}>
                  <SearchLocation
                    types={'countries,regions,cities'}
                    clearButton={false}
                    usePortal={true}
                    selectLocation={(l) => (staticData.showCountriesList(false), history.push(lang.l(l.link)))}
                  />
                  <div className="world-part-countries-popup-title">
                    <Link to={lang.l(`/${countriesData.worldPart}`)}>{lang.t(countriesData.worldParts.filter((p) => p.id == countriesData.worldPart)[0].name)}</Link>
                  </div>
                  {subregions.length > 1 && <MultiRowTabs
                    tabs={subregions.map((sr, i) => countriesData.countries[countriesData.worldPart].filter((c) => c.world_part === sr.title).length && { id: i, name: lang.t(sr.title) }).filter((sr) => sr)}
                    currentTab={countriesData?.subRegion}
                    onChange={(d) => staticData.setTopCountriesData({ ...countriesData, subRegion: d })}
                  />}
                </div>
                <div className="scroll" style={scrollStyle}>
                  <div>
                    <ul className="world-part-countries-list">
                      {countriesData.countries[countriesData.worldPart].filter((c) => c.world_part == countriesData.worldParts.filter((wp) => wp.id == countriesData.worldPart)[0].subregions[countriesData.subRegion].title).map((c) => <li>
                        <Link onClick={handleClosePopup} to={lang.l(`/${c.link}`)}>
                          {c.flag && <img src={Helper.getFileUrl('country', c.flag)}/>}
                          <span>{Helper.getFieldValue(c.name, lang.currentLanguage._id)}</span>
                        </Link>
                      </li>)}
                    </ul>
                    <div className="world-part-text" dangerouslySetInnerHTML={{ __html: Helper.getFieldValue(countriesData.pages[countriesData.worldPart].text, lang.currentLanguage._id) }}/>
                  </div>
                </div>
              </> :
              (countriesListVisible ? <LoadingScreen/> : '')
            }
          </div>
        </ResizeSensible>
      </Popup>
    </>
  );
}

export default Top;
