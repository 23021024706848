import React, { useContext, useState, useEffect } from 'react';
import { IS_SERVER } from 'src/constants';
import { FrontContext } from 'src/front/helpers/FrontContext';
import useLang from 'src/hooks/useLang';
import $ from 'jquery';
import { LeftArrow, Cross } from './icons';
import SearchLocation from 'src/front/components/SearchLocation';

function Rss() {
  const frontContext = useContext(FrontContext);
  const lang = useLang('Rss');
  const [sections, setSections] = useState({ render: ['common'] });
  const [firstRender, setFirstRender] = useState(true);

  const handleOpenSection = (ev, section) => {
    ev.preventDefault();

    if (sections.render.length === 1 && !sections.hidden) {
      $('#rss-popup-wrapper .rss-popup-body').css('overflow', 'hidden');
      setSections({ render: [sections.render[0], section], hidden: section });
    }
  };

  useEffect(() => {
    if (sections.render.length === 2 && sections.hidden) {
      const height = $(`#rss-popup-${sections.render[1]}`).height();
      const animationDuration = 300;

      $(`#rss-popup-${sections.render[0]}`).animate({ opacity: 0 }, animationDuration);
      $(`#rss-popup-${sections.render[1]}`).animate({ opacity: 1 }, animationDuration);
      $('#rss-popup-wrapper .rss-popup-body').animate({ height: `${height}px` }, animationDuration, () => setSections({ render: [sections.render[1]] }));
    } else $(`#rss-popup-wrapper .rss-popup-body, #rss-popup-${sections.render[0]}`).removeAttr('style');
  }, [sections]);

  useEffect(() => {
    setFirstRender(false);
  }, []);

  return '';

  // eslint-disable-next-line no-unreachable
  return (
    !IS_SERVER && !firstRender ?
    <div id="rss-popup-wrapper" data-popup-name="rss-popup" className={`full-screen-popup animated-visibility${frontContext.popup === 'rss-popup' ? ' visible' : ''}`}>
      <div id="rss-popup" {...(frontContext.popup === 'rss-popup' ? { className: 'opened' } : {})}>
        {/* eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid */}
        <a href="#" className="close-popup"></a>
        <div className="rss-popup-header">RSS канал с погодными данными по Израилю</div>
        <div className="rss-popup-body-wrapper">
          <div className="rss-popup-body relative">
            {
              sections.render.includes('common') &&
              <div id="rss-popup-common" {...sections.hidden === 'common' ? { className: 'hidden' } : {}}>
                <div>Pogoda.co.il - погода в Израиле предлагает для вашего сайта, блога или странице в социальных сетях бесплатные RSS каналы с погодными данными в израильских городах.</div>
                <ul>
                  <li>
                    <div>
                      {/* eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid */}
                      <a onClick={(ev) => handleOpenSection(ev, 'evening')} href="#">Вечерний прогноз на завтра</a>
                    </div>
                    <div>18:40</div>
                  </li>
                  <li>
                    <div>
                      {/* eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid */}
                      <a onClick={(ev) => handleOpenSection(ev, 'morningSun')} href="#">Утренний прогноз на сегодня с солнцем</a>
                    </div>
                    <div>06:40</div>
                  </li>
                  <li>
                    <div>
                      {/* eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid */}
                      <a onClick={(ev) => handleOpenSection(ev, 'morning')} href="#">Утренний прогноз на сегодня</a>
                    </div>
                    <div>03:05</div>
                  </li>
                  <li>
                    <div>
                      {/* eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid */}
                      <a onClick={(ev) => handleOpenSection(ev, 'byCities')} href="#">Погода в городах</a>
                    </div>
                    <div dangerouslySetInnerHTML={{ __html: 'Каждые 30 минут'.replace(/ ([^ ]*)$/, '&nbsp;$1') }}/>
                  </li>
                </ul>
                <div>
                  <b>Как получить индивидуальный погодный RSS канал?</b><br/><br/>
                  <div>Если вашему проекту нужно больше подробной погодной информации, мы готовы предоставить расширенный RSS канал с погодой по израилю и всему миру. Связатся с нами</div>
                </div>
              </div>
            }
            {
              sections.render.includes('evening') &&
              <div id="rss-popup-evening" className={`rss-popup-section${sections.hidden === 'evening' ? ' hidden' : ''}`}>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a className="rss-popup-back-to-common" onClick={(ev) => handleOpenSection(ev, 'common')} href="#">
                  <LeftArrow className="inline-block va-middle"/>
                  <div className="inline-block va-middle">{lang.t('Back')}</div>
                </a>
                <div>
                  <EveningForecast lang={lang}/>
                </div>
              </div>
            }
            {
              sections.render.includes('morningSun') &&
              <div id="rss-popup-morningSun" className={`rss-popup-section${sections.hidden === 'morningSun' ? ' hidden' : ''}`}>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a className="rss-popup-back-to-common" onClick={(ev) => handleOpenSection(ev, 'common')} href="#">
                  <LeftArrow className="inline-block va-middle"/>
                  <div className="inline-block va-middle">{lang.t('Back')}</div>
                </a>
                <div>
                  <MorningForecastWithSun lang={lang}/>
                </div>
              </div>
            }
            {
              sections.render.includes('morning') &&
              <div id="rss-popup-morning" className={`rss-popup-section${sections.hidden === 'morning' ? ' hidden' : ''}`}>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a className="rss-popup-back-to-common" onClick={(ev) => handleOpenSection(ev, 'common')} href="#">
                  <LeftArrow className="inline-block va-middle"/>
                  <div className="inline-block va-middle">{lang.t('Back')}</div>
                </a>
                <div>
                  <MorningForecast lang={lang}/>
                </div>
              </div>
            }
            {
              sections.render.includes('byCities') &&
              <div id="rss-popup-byCities" className={`rss-popup-section${sections.hidden === 'byCities' ? ' hidden' : ''}`}>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a className="rss-popup-back-to-common" onClick={(ev) => handleOpenSection(ev, 'common')} href="#">
                  <LeftArrow className="inline-block va-middle"/>
                  <div className="inline-block va-middle">{lang.t('Back')}</div>
                </a>
                <div>
                  <ForecastByCities lang={lang}/>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </div> : null
  );
}

function EveningForecast({ lang }) {
  const [city, setCity] = useState(null);

  const handleRemoveCity = (ev) => {
    ev.preventDefault();
    setCity(null);
  };

  return (
    <>
      <div>{lang.t('Evening forecast for tomorrow')}</div>
      {
        city ?
        <>
          <div>
            <div className="inline-block va-middle">{city.country}, {city.name}</div>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a className="inline-block va-middle" href="#" onClick={handleRemoveCity}>
              <Cross className="block" color="#545D66"/>
            </a>
          </div>
          <div>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a className="inline-block va-middle" href="#">{lang.t('Open link')}</a>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a className="inline-block va-middle" href="#">{lang.t('Copy link')}</a>
          </div>
        </> :
        <SearchLocation
          types={'cities'}
          clearButton={false}
          usePortal={false}
          selectLocation={(l) => setCity(l)}
        />
      }
    </>
  );
}

function MorningForecastWithSun({ lang }) {
  const [city, setCity] = useState(null);

  const handleRemoveCity = (ev) => {
    ev.preventDefault();
    setCity(null);
  };

  return (
    <>
      <div>{lang.t('Morning forecast for today with sun')}</div>
      {
        city ?
        <>
          <div>
            <div className="inline-block va-middle">{city.country}, {city.name}</div>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a className="inline-block va-middle" href="#" onClick={handleRemoveCity}>
              <Cross className="block" color="#545D66"/>
            </a>
          </div>
          <div>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a className="inline-block va-middle" href="#">{lang.t('Open link')}</a>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a className="inline-block va-middle" href="#">{lang.t('Copy link')}</a>
          </div>
        </> :
        <SearchLocation
          types={'cities'}
          clearButton={false}
          usePortal={false}
          selectLocation={(l) => setCity(l)}
        />
      }
    </>
  );
}

function MorningForecast({ lang }) {
  const [city, setCity] = useState(null);

  const handleRemoveCity = (ev) => {
    ev.preventDefault();
    setCity(null);
  };

  return (
    <>
      <div>{lang.t('Morning forecast for today')}</div>
      {
        city ?
        <>
          <div>
            <div className="inline-block va-middle">{city.country}, {city.name}</div>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a className="inline-block va-middle" href="#" onClick={handleRemoveCity}>
              <Cross className="block" color="#545D66"/>
            </a>
          </div>
          <div>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a className="inline-block va-middle" href="#">{lang.t('Open link')}</a>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a className="inline-block va-middle" href="#">{lang.t('Copy link')}</a>
          </div>
        </> :
        <SearchLocation
          types={'cities'}
          clearButton={false}
          usePortal={false}
          selectLocation={(l) => setCity(l)}
        />
      }
    </>
  );
}

function ForecastByCities({ lang }) {
  const [cities, setCities] = useState([]);

  const handleRemoveCity = (ev, i) => {
    ev.preventDefault();
    setCities(cities.filter((c, n) => n !== i));
  };

  return (
    <>
      <div>{lang.t('Weather in cities')}</div>
      {
        <SearchLocation
          types={'cities'}
          clearButton={false}
          usePortal={false}
          selectLocation={(l) => setCities([...cities, l])}
        />
      }
      <ul className="locations-list">
        {cities.map((c, i) => (
          <li>
            <div>{c.country}, {c.name}</div>
            {/* eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid */}
            <a onClick={(ev) => handleRemoveCity(ev, i)} href="#"></a>
          </li>
        ))}
      </ul>
      {
        !!cities.length &&
        <div>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a className="inline-block va-middle" href="#">{lang.t('Open link')}</a>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a className="inline-block va-middle" href="#">{lang.t('Copy link')}</a>
        </div>
      }
    </>
  );
}

export default Rss;