import React, { useState, useRef, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useHistory } from 'react-router';
import Top from 'src/front/components/Top';
import Footer from 'src/front/components/Footer';
import ComponentStyle from 'src/components/ComponentStyle';
import SplashScreen from 'src/components/SplashScreen';
import LoadingScreen from 'src/components/LoadingScreen';
import useLang from 'src/hooks/useLang';
import Auth from 'src/front/components/Auth';
import routes from 'src/front/components/RoutesList';
import Helper from 'src/front/helpers/Helper';
import { useSelector } from 'react-redux';
import { FrontContext } from 'src/front/helpers/FrontContext';
import axios from 'axios';
import { API_URI, IS_SERVER } from 'src/constants';
import Avatar from 'src/components/Avatar';
import drag from 'src/components/Draggable';
import { useSnackbar } from 'notistack';
import SearchLocation from 'src/front/components/SearchLocation';
import globalConfig from 'src/utils/globalConfig';
import Link from 'src/front/components/Link';
import DimensionUnitsConvertor from 'src/utils/DimensionUnitsConvertor';
import ResizeSensible from 'src/front/components/ResizeSensible';
import Rss from 'src/front/components/Rss';
import Main from 'src/front/layouts/Main';
import microdataGenerator from 'src/utils/microdata';
import AccountManagementLayout from 'src/front/views/AccountManagement/AccountManagementLayout';
import { userService, pushNotificationsService } from 'src/services';

const fallbacks = {
  loading: ({ bodyClass, ...props }) => { document.body.className = bodyClass; return <LoadingScreen/>; },
  base: ({ bodyClass, ...props }) => { document.body.className = bodyClass; return <Main {...props}><LoadingScreen/></Main>; },
  accountManagement: ({ bodyClass, ...props }) => { document.body.className = bodyClass; return <AccountManagementLayout {...props}><LoadingScreen/></AccountManagementLayout>; },
  countriesList: ({ bodyClass }) => { document.body.className = bodyClass; return <div><LoadingScreen/></div>; },
  home: ({ lang, ...props }) => { 
    if (lang.currentLocalization.mapType !== 'landing') {
      document.body.className = 'home'; 
      return <Main {...props}><LoadingScreen/></Main>;
    } else {
      document.body.className = 'home landing'; 
      return <LoadingScreen/>;
    }
  },
};

function Front(props) {
  const { language: { languages, lang: language, langPrefix, localization }, account: { user } } = useSelector(({ language, account }) => ({ language, account }));
  const history = useHistory();
  const [context, setContext] = useState({
    popup: false,
    myCities: /*props.staticContext ?
      (props.staticContext.myCities ? props.staticContext.myCities : []) :*/
      (typeof window == 'object' && window.__REACT_INIT_DATA__ && window.__REACT_INIT_DATA__.myCities ? window.__REACT_INIT_DATA__.myCities : []),
    editingMyCities: false,
    sidebarLocations: /*props.staticContext ?
      (props.staticContext?.sidebarLocations || null) :*/
      (typeof window == 'object' && window.__REACT_INIT_DATA__ && window.__REACT_INIT_DATA__.sidebarLocations ? window.__REACT_INIT_DATA__.sidebarLocations : null),
    staticData: {},
    dimensionUnits: /*props.staticContext ?
      (props.staticContext?.dimensionUnits || null) :*/
      (typeof window == 'object' && window.__REACT_INIT_DATA__ && window.__REACT_INIT_DATA__.dimensionUnits ? window.__REACT_INIT_DATA__.dimensionUnits : null),
    stickyInstance: {
      add: (f) => stickyInstances.current.instances.push(f),
      remove: (f) => stickyInstances.current.instances = stickyInstances.current.instances.filter((c) => c !== f),
      get: () => stickyInstances.current.instances,
      setRule: (r, v) => r in stickyInstances.current && r !== 'instances' && (stickyInstances.current[r] = v)
    },
    is404: props.staticContext ? props.staticContext.is404 : (typeof window == 'object' && window.__REACT_INIT_DATA__ && window.__REACT_INIT_DATA__.is404 ? true : false)
  });
  const fronContextValue = {
    ...context,
    setProperty: (p, v) => setContext({ ...context, ...typeof p == 'string' ? { [p]: v } : p }),
    removeProperty: (p) => ((typeof p === 'string' ? [p] : p).forEach((p) => p in context && delete context[p]), setContext({ ...context }))
  };
  const lang = useLang({ component: 'Front', fronContext: fronContextValue });
  if (typeof window == 'object' && window.__REACT_INIT_DATA__ && window.__REACT_INIT_DATA__.myCities) delete window.__REACT_INIT_DATA__.myCities;
  if (typeof window == 'object' && window.__REACT_INIT_DATA__ && window.__REACT_INIT_DATA__.sidebarLocations) delete window.__REACT_INIT_DATA__.sidebarLocations;
  const { enqueueSnackbar } = useSnackbar();
  const [editingMyCity, setEditingMyCity] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const lastsidebarLocationsCountry = useRef(null);
  const stickyInstances = useRef({ instances: [], disableCallbacksOnce: false });
  const firstRender = useRef(!IS_SERVER);
  const [firstRenderState, setFirstRenderState] = useState(true);
  const contextRef = useRef(null);

  contextRef.current = context;

  useEffect(() => {
    if (context.myCities[0]?.constructor === Object) {
      pushNotificationsService.setWeatherWarningsParams(user ? user._id : null, lang.currentLanguage._id, context.myCities[0].id);
    }
  }, IS_SERVER ? [] : [user ? user._id : null, lang.currentLanguage._id, context.myCities[0]?.constructor === Object ? context.myCities[0]._id : context.myCities[0]]);

  useEffect(() => {
    if (firstRender.current) firstRender.current = false;
    else if (context.is404) setContext({ ...contextRef.current, is404: false });
  }, [props.location.pathname]);

  useEffect(() => {
    userService.executeClientSystemTasks(user);
  }, [user]);

  useEffect(() => {
    setFirstRenderState(false);
  }, []);

  const handleChangeCities = () => setContext({ ...contextRef.current, editingMyCities: [...context.myCities] });

  const handleSaveCities = () => {
    setSearchResults([]);
    setEditingMyCity(false);
    setContext({ ...contextRef.current, myCities: context.editingMyCities, editingMyCities: false });
    Helper.setCookie('my_cities', context.editingMyCities.map((c) => c.id).join(','), { expires: new Date(Date.now() + 60 * 60 * 24 * 365 * 100000) });
  };

  const handleSetMyCity = (city) => {
    if (!context.editingMyCities.filter((c) => c.id == city.id).length) {
      const cities = [...context.editingMyCities];

      cities[editingMyCity] = city;

      setContext({ ...contextRef.current, editingMyCities: cities });
    } else {
      enqueueSnackbar(lang.t('!city is already chosen', { '!city': city.name }), {
        variant: 'error'
      });
    }

    setSearchResults([]);
    setEditingMyCity(false);
  };

  const getSidebarLocations = (country) => {
    if (!IS_SERVER && lastsidebarLocationsCountry.current != country) {
      lastsidebarLocationsCountry.current = country;

      axios
          .get(`${API_URI}/sidebar-cities${country != 'israel' ? '?country=' + encodeURIComponent(country) : ''}`, { withCredentials: true })
          .then(({ data }) => data.country == lastsidebarLocationsCountry.current && setContext({ ...contextRef.current, sidebarLocations: data }));
    }
  };

  const getMyCities = (country) => {
    if (!IS_SERVER && !context.myCities.filter((c) => c?.constructor === Object).length) {
      const myCities = Helper.getCookie('my_cities');
      axios
        .get(`${API_URI}/my-cities/${country ||'israel'}/${myCities ? myCities.replace(/[^a-z0-9,]/gi, '') : 'false'}/${language}`, { withCredentials: true })
        .then(({ data }) => {
          const cookie = data.map((c) => c.id).join(',');
  
          if (myCities != cookie) {
            Helper.setCookie('my_cities', cookie, { expires: new Date(Date.now() + 60 * 60 * 24 * 365 * 100000) });
          }
          setContext({ ...contextRef.current, myCities: data });
        });
    }
  };

  const MyCityTag = context.editingMyCities ? 'div' : Link;
  const Page404 = context.is404 ? routes.filter((r) => r.name == '404')[0].component : null;
  const showTop = IS_SERVER ?
    "!['article', 'article edit'].includes(route.name)" :
    !['/article/'].reduce((a, v) => a || window.location.pathname.startsWith(lang.l(v)), false)
  const showFooter = IS_SERVER ?
    "!['map', 'articles', 'unpublished articles', 'article edit'].includes(route.name) && (route.name !== 'home' || staticData.language.localizations[localization].mapType != 'landing')" : 
    !['/europe', '/america', '/cis', '/asia', '/africa', '/australia_newzealand', '/articles', '/unpublished-articles', '/article/edit/'].reduce((a, v) => a || window.location.pathname.startsWith(lang.l(v)), false) && (lang.currentLocalization.mapType !== 'landing' || window.location.pathname.replace(/\/+$/, '') !== lang.l('/').replace(/\/+$/, ''));

  return (
    <FrontContext.Provider value={fronContextValue}>
      <ResizeSensible callback={() => stickyInstances.current.disableCallbacksOnce ? (stickyInstances.current.disableCallbacksOnce = false) : stickyInstances.current.instances.forEach((i) => i.callback())}>
        <Auth>
          <ComponentStyle styles={IS_SERVER ? 'Helper.getFrontStyles(staticData.language, staticData.language.localizations[localization])' : Helper.getFrontStyles(lang.currentLanguage, lang.currentLanguage.localizations[localization])} Preloader={<SplashScreen/>}>
            {
              context.is404 ?
              <Page404/> :
              <>
                <div id="wrapper">
                  {
                    showTop &&
                    <>
                      {IS_SERVER && `{if (${showTop}) :}`}
                      <Top {...props}/>
                      {IS_SERVER && '{endif}'}
                    </>
                  }
                  <section id="main" className="clear">
                    <Switch>
                      {routes.filter((r) => r.name != '404').map((r, i) => (
                        <Route
                          key={i}
                          path={IS_SERVER ? r.path : (typeof r.path === 'string' ? lang.l(r.path) : r.path.map((p) => lang.l(p)))}
                          exact={r.exact}
                          render={(props) => {
                            const Component = r.component;
                            const Layout = r.layout;
                            let Fallback =  null;
                            let fallbackProps = {};

                            if (!IS_SERVER && 'fallback' in r) {
                              Fallback = fallbacks[r.fallback.name];

                              r.fallback.props.forEach((p) => {
                                if (p.constructor === Array) fallbackProps[p[0]] = p[1];
                                else {
                                  switch (p) {
                                    case 'lang': fallbackProps[p] = lang; break;
                                  }
                                }
                              });
                            }

                            let detectedCountry;
    
                            switch (props.match.path) {
                              case lang.l('/news/:country?'): detectedCountry = props.match.params.country || 'israel'; break;
                              default: detectedCountry = props.match.params.country || user?.countryLink || context.myCities[0]?.link?.replace(/^\/|\/[^/]*$/g, '') || 'israel';
                            }
    
                            if (context.sidebarLocations?.country != detectedCountry) getSidebarLocations(detectedCountry);
    
                            getMyCities(props.match.params.country);
    
                            if (!IS_SERVER && !r.microdataName) microdataGenerator();

                            if (!IS_SERVER) {
                              switch (r.name) {
                                case 'home': case 'map': case 'countries': case 'privacy policy': case 'user deletion': case 'beach': case 'news inner': case 'region city':
                                case 'account management widgets': case 'account management password recovery': case 'account management': case 'article edit': case 'articles':
                                case 'unpublished articles':
                                  if (props.location.pathname !== '/' && props.location.pathname.endsWith('/')) history.replace({ pathname: props.location.pathname.replace(/\/+$/, ''), search: props.location.search, state: { isActive: true }});
                                  break;

                                case 'advertising': case 'widgets': case 'partnership': case 'service information': case 'feedback': case 'beaches': case 'news list': 
                                case 'country': case 'rss':
                                  if (!props.location.pathname.endsWith('/')) history.replace({ pathname: `${props.location.pathname}/`, search: props.location.search, state: { isActive: true }});
                                  break;
                              }

                              let canonicalLink = '';
                              let canonicalTag = document.querySelector('[rel="canonical"]');

                              switch (r.name) {
                                case 'news list': {
                                  // const page = window.location.search && /^\?\d+(?:&|$)/.test(window.location.search) ? window.location.search.replace(/^\?(\d+).*/, '$1') : '';
                                  // canonicalLink = `${window.location.protocol}//${window.location.host}${window.location.pathname}${page && page !== '1' ? `?${page}` : ''}`; 
                                  canonicalLink = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
                                  break;
                                }

                                case 'map': {
                                  let url = window.location.pathname.split('/').slice(0, langPrefix ? 3 : 2).join('/');

                                  canonicalLink = `${window.location.protocol}//${window.location.host}${url}`;
                                  break;
                                }

                                default: canonicalLink = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
                              }

                              if (!canonicalTag) {
                                canonicalTag = document.createElement('link');
                                canonicalTag.setAttribute('rel', 'canonical');
                                document.head.appendChild(canonicalTag);
                              }

                              canonicalTag.setAttribute('href', canonicalLink);

                              const currentLanguage = languages.filter((l) => l._id == language)[0];
                              const url = window.location.href.substring(window.location.protocol.length + window.location.host.length + 2);
                              const baseURL = currentLanguage.localizations[localization].domain || currentLanguage._id == globalConfig('defaultLanguageShortName') ? url : url.substring(currentLanguage.localizations[localization].shortName.length + 1);
                              const { protocol, host, pathname, search = '', hash = '' } = window.location;

                              languages.forEach((lang) => {
                                if (lang.active && lang.ready && !lang.deleting) {
                                  lang.localizations.forEach((loc, i) => {
                                    const url = `${loc.domain || globalConfig('defaultClientUri')}${!loc.domain && !loc.default ? '/' + loc.shortName : ''}${baseURL}`;
                                    //const xDefault = ((loc.default && (!currentLanguage.localizations[localization].domain || (currentLanguage._id == lang._id && i === localization))) ||
                                    //                  (loc.domain && currentLanguage._id == lang._id && i === localization)) && 'x-default';
                                    const xDefault = loc.default && 'x-default';
                                    let link = document.querySelector(`link[rel="alternate"][hreflang="${xDefault || loc.shortName}"]`);
                              
                                    if (link) link.setAttribute('href', url);
                                    else {
                                      link = document.createElement('link');
                              
                                      [['rel', 'alternate'], ['hreflang', xDefault || loc.shortName], ['href', url ]].map(([attr, val]) => link.setAttribute(attr, val));
                              
                                      document.head.appendChild(link);
                                    }
                                  });
                                }
                              });
                            }
              
                            return (
                              Layout ? (
                                <Layout {...props}>
                                  <Component {...props} {...Fallback && { fallback: <Fallback {...fallbackProps} {...props}/> }}/>
                                </Layout>
                              ) : (
                                <Component {...props} {...Fallback && { fallback: <Fallback {...fallbackProps} {...props}/> }}/>
                              )
                            );
                          }}
                        />
                      ))}
                      <Route
                        path="*"
                        render={(props) => (microdataGenerator(), <Set404 context={context} setContext={setContext}/>)}
                      />
                    </Switch>
                  </section>
                </div>
                {
                  showFooter &&
                  <>
                    {IS_SERVER && `{if (${showFooter}) :}`}
                      <Footer {...props}/>
                    {IS_SERVER && '{endif}'}
                  </>
                }
              </>
            }
          </ComponentStyle>
        </Auth>
        {!IS_SERVER && !firstRenderState && <div id="popup-background" {...(context.popup ? { className: 'opened' } : {})}/>}
        <Rss/>
        {
          !IS_SERVER && !firstRenderState &&
          <div id="my-cities-popup" data-popup-name="my-cities-popup" className={`full-screen-popup animated-visibility${context.popup == 'my-cities-popup' ? ' visible' : ''}`}>
            <div { ...context.popup == 'my-cities-popup' ? { className: 'opened' } : {} }>
              <a href="#" className="close-popup"></a>
              <div className="top fs-0">
                {user && <>
                  <Avatar
                    firstName={user.firstName}
                    lastName={user.lastName}
                    width="64"
                    height="64"
                    fontSize="30"
                    className="inline-block va-middle"
                    src={user.avatar ? Helper.getFileUrl('user-avatar', user.avatar) : ''}
                  />
                  <div className="inline-block va-middle">
                    <div className="name">{user.firstName} {user.lastName}</div>
                    {context.myCities.length ? <div className="country">{context.myCities[0].country}</div> : ''}
                  </div>
                </>}
              </div>
              <div className="content">
                <h3>
                  <span>{lang.t('My cities')}</span>:
                </h3>
                <ul className={`cities-list${context.editingMyCities ? ' editing' : ''}`}>
                  {(context.editingMyCities || context.myCities).filter((c) => c?.constructor === Object).map((c, i, a) => <li key={c.id} data-key={c.id} className={`clear drag-sortable-element${context.editingMyCities && editingMyCity === i ? ' editing' : ''}${!i ? ' first' : (i + 1 == a.length ? ' last' : '')}`}>
                    <MyCityTag {...!context.editingMyCities ? { to: lang.l(c.link), className: 'close-popup' } : {}}>
                      <div className="city-name-temp">
                        <div className="city-name">{c.name}</div>
                        <div className="city-night-temp">
                          <span>{lang.t('At night')}</span> <span className="inline-block tempr">{DimensionUnitsConvertor.temperature(c.weather.nightTemp, context.dimensionUnits.temperature)}</span>
                        </div>
                        <div className="city-tomorrow-temp">
                          <span>{lang.t('Tomorrow')}</span> <span className="inline-block tempr">{DimensionUnitsConvertor.temperature(c.weather.tomorrowTemp, context.dimensionUnits.temperature)}</span>
                        </div>
                      </div>
                      <img width="284" height="284" src={Helper.getFileUrl('weather-icon', `${c.weather.icon}.svg`)}/>
                      <div className="city-temp">
                        <div className="tempr">{DimensionUnitsConvertor.temperature(c.weather.temp, context.dimensionUnits.temperature)}</div>
                        <div className="city-night-temp">
                          <span>{lang.t('At night')}</span> <span className="inline-block tempr">{DimensionUnitsConvertor.temperature(c.weather.nightTemp, context.dimensionUnits.temperature)}</span>
                        </div>
                        <div className="city-tomorrow-temp">
                          <span>{lang.t('Tomorrow')}</span> <span className="inline-block tempr">{DimensionUnitsConvertor.temperature(c.weather.tomorrowTemp, context.dimensionUnits.temperature)}</span>
                        </div>
                      </div>
                      <img width="284" height="284" src={Helper.getFileUrl('weather-icon', `${c.weather.icon}.svg`)}/>
                      {context.editingMyCities && editingMyCity === false && <ul className="actions">
                        {i && <li>
                          <button onClick={() => setContext({ ...context, editingMyCities: context.editingMyCities.map((c, n) => context.editingMyCities[n == i - 1 ? i : (n == i ? n - 1 : n)]) })} className="move-up"></button>
                        </li>}
                        <li>
                          <button onClick={() => setEditingMyCity(i)} className="edit"></button>
                        </li>
                        <li>
                          <button onMouseDown={(event) => drag({ event, setKeys: (p) => setContext({ ...context, editingMyCities: [...context.editingMyCities].sort((a, b) => p.indexOf(a.id) - p.indexOf(b.id)) }) })} className="move"></button>
                        </li>
                        {i + 1 < (context.editingMyCities || context.myCities).length && <li>
                          <button onClick={() => setContext({ ...context, editingMyCities: context.editingMyCities.map((c, n) => context.editingMyCities[n == i ? n + 1 : (n == i + 1 ? i : n)]) })} className="move-down"></button>
                        </li>}
                      </ul>}
                      {context.editingMyCities &&
                        <>
                          <SearchLocation
                            selectLocation={handleSetMyCity}
                            onClear={() => setEditingMyCity(false)}
                            types={'cities'}
                            params={{ weather: '1' }}
                          />
                        </>
                      }
                    </MyCityTag>
                  </li>)}
                </ul>
                <div className="ta-center">
                  {
                    context.editingMyCities ?
                    <button onClick={handleSaveCities} className="save green-button">{lang.t('Save')}</button> :
                    <button onClick={handleChangeCities} className="change">{lang.t('Change')}</button>
                  }
                </div>
              </div>
            </div>
          </div>
        }
      </ResizeSensible>
    </FrontContext.Provider>
  );
}

const Set404 = ({ context, setContext }) => {
  useEffect(() => {
    setContext({ ...context, is404: true });
  }, []);

  return null;
};

export default Front;